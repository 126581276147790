import React from 'react'

export const HeaderSocials = () => {
  return (
    <div className="home__socials">
        <a href="https://www.instagram.com/hugo_braj/" className="home__social-link" target='_blank'>
        <i className="fa-brands fa-instagram"></i>
        </a>
        <a href="https://www.linkedin.com/in/hugo-brajdic-b655b2235/?originalSubdomain=fr" className="home__social-link" target='_blank'>
        <i className="fa-brands fa-linkedin"></i>
        </a>
        <a href="" className="home__social-link" target='_blank'>
        <i className="fa-brands fa-facebook"></i>
        </a>
    </div>
  )
}
export default HeaderSocials