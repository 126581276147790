import React from 'react'
import "./home.css"
import HeaderSocials from './HeaderSocials'
import { useTypingEffect } from '../../hooks/typing-effect'

const Home = () => {
  const typingText = useTypingEffect("Bonjour 👋 ", 50);
  const typingText2 = useTypingEffect("Je suis développeur informatique 👨‍💻 ", 50);
  return (
    <section className="container" id='home' >
      <div className="home__intro">
        <svg width="94" height="88" viewBox="0 0 94 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className='pathSignature' d="M23.3405 6.40903C23.3405 6.40903 38.8246 46 42 70C43.0881 78.2244 33 77 31.5 65C30.111 53.8877 33.1426 50.9724 39.6754 39.4374C46.8161 26.8288 47.1665 6.40903 47.0104 3.09064C46.8544 -0.227752 55.2819 88.7984 53.7213 68.9399C52.1606 49.0815 51.9005 25.2824 51.9005 18.8011C51.9005 12.3199 62.4341 7.76327 71.2526 12.3199C78.0221 15.8178 81.839 21.6774 80.9286 29.2229C79.5731 40.4584 53.7213 39.4374 53.7213 39.4374C53.7213 39.4374 74.9378 33.9125 83.4777 41.8225C88.1634 46.1625 91.3521 49.9764 90.9688 56.3404C90.6251 62.0496 87.4696 68.1495 83.4777 72.2583C59.5997 96.8351 5.96524 80.9172 3 65.7771" stroke="#B0DEFF" strokeWidth="5" strokeLinecap="round" />
        </svg>
        <svg width="214" height="37" viewBox="0 0 214 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path className='pathName' d="M2.5 29V16.5M2.5 4V16.5M2.5 16.5H18M18 16.5V4M18 16.5V29M27 10C27 10 25 27 30.5 28C36 29 39.5 24.5 40 21.5M40 21.5C40.5 18.5 40 10 40 10M40 21.5C40 21.5 39.5 25.5 40.5 29M64.5 9C61 9.5 60 11.5 60 11.5M60 11.5C60 11.5 50.5 7 49 15.5C48.0294 21 53.5 21.5 54.5 21.5M60 11.5C62.5 12.5 62 17.5 60.5 19.5C59 21.5 55.5 21.5 54.5 21.5M54.5 21.5C45.5 22.5 50.5 26.5 55.5 26.5M55.5 26.5C64 26.5 63 31 63 31C63 35.5 48 37.5 48 31C48 28.5 50.5 27 55.5 26.5ZM100 16V5C100 5 112.5 3 113 10C113.5 17 100 16 100 16ZM100 16V28C100 28 114 30 114 22C114 14 100 16 100 16ZM122 27.5V19.25M122 10.5V19.25M122 19.25C122.5 12.5 127 9.5 130 11M136.5 12.5C140 9.5 147.5 10 148 14C147.926 15.4822 147.874 16.8107 147.842 18M149 27.5C148.515 27.2577 148.148 26.4284 147.955 24.5M147.842 18C147.842 18 135 18.5 135.5 24.5C136.5 30 146.5 26.5 147.955 24.5M147.842 18C147.763 20.9463 147.809 23.0387 147.955 24.5M157 10.5V31C157 34.5 154.753 35.2759 151.5 34.5M157 3C157 3.39052 157 4 157 4M179 2V13.5M179 23.5C179 23.5 179.5 25.5 180 27.5M179 23.5C177.5 27.5 165.306 33.0793 165 18.5C166 5.22903 178 11.5 179 13.5M179 23.5V13.5M189 3V4M189 10.5V28M211.5 23.5C210 27.5 197.306 33.0793 197 18.5C197.901 5.91552 210.951 10.7308 211 14.5M76 10C66 10 67 28.5 76 28.5C85 28.5 86 10 76 10Z" stroke="#E6A051" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <div className="home__education">
          {typingText} <br></br>
          {typingText2}
        </div>
        <HeaderSocials />
      </div>
    </section>
  )
}

export default Home